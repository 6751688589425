.rmdp-range:has(.ganztag) {
    background-color: #C9D6EA !important;
}

.rmdp-range:has(.vormittag) {
    background-color: #84C318;
}

.rmdp-range:has(.nachmittag) {
    background-color: #7AE7C7;
}


div.ganztag {
    background-color: #C9D6EA !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

div.vormittag {
    background-color: #84C318 !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

div.nachmittag {
    background-color: #7AE7C7 !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}


.un-availble {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.un-availble p {
    margin: 0;
}

.highlight-red {
    background-color: #ff6687;
    color: #fff !important;
}

.highlight-vormittag,
.rmdp-vormittag,
.hover-vormittag:hover {
    background-color: #84C318 !important;
}

.highlight-nachmittag,
.rmdp-nachmittag,
.hover-nachmittag:hover {
    background-color: #7AE7C7 !important;
}

.highlight-ganztag,
.rmdp-ganztag,
.hover-ganztag:hover {
    background-color: #C9D6EA !important;
}

.capitalize {
    text-transform: capitalize;
}

.selector-calendar-group {
    display: flex;
    margin-bottom: 45px;

    .slot-selector {
        flex-grow: 2;
        margin-left: 30px;
        margin-top: 0;
        border-top: 0;
        border-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;

        &__abschnitt {
            margin-bottom: 15px;

            h3 {
                font-size: 16px;
            }
        }
    }
}

.chosen-slots {
    border: 2px solid #FFA74D;
    background: #FFD699;
    padding: 15px 20px;
    border-radius: 5px;
    padding: 5px 10px;
}